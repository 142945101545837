import axios from '@/common/axios'
import qs from 'qs'

export function addPurifiedWaterDisinfection (data) {
  return axios({
    method: 'post',
    url: '/device/purifiedWaterDisinfection/add',
    data: qs.stringify(data)
  })
}

export function deletePurifiedWaterDisinfection (id) {
  return axios({
    method: 'delete',
    url: '/device/purifiedWaterDisinfection/delete/' + id
  })
}

export function updatePurifiedWaterDisinfection (data) {
  return axios({
    method: 'put',
    url: '/device/purifiedWaterDisinfection/update',
    data: qs.stringify(data)
  })
}

export function selectPurifiedWaterDisinfectionInfo (id) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterDisinfection/info/' + id
  })
}

export function selectPurifiedWaterDisinfectionList (query) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterDisinfection/list',
    params: query
  })
}
