<template>
  <div id="purifiedWaterDisinfection">
    <el-dialog
      :title="purifiedWaterDisinfectionFormTitle"
      width="1500px"
      :visible.sync="purifiedWaterDisinfectionDialogVisible"
      :close-on-click-modal="false"
      @close="purifiedWaterDisinfectionDialogClose"
    >
      <el-form
        ref="purifiedWaterDisinfectionFormRef"
        :model="purifiedWaterDisinfectionForm"
        :rules="purifiedWaterDisinfectionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="设备编号" prop="deviceId">
              <el-select
                v-model="purifiedWaterDisinfectionForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="purifiedWaterDisinfectionForm.deviceName" placeholder="请输入设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年份" prop="year">
              <el-input v-model="purifiedWaterDisinfectionForm.year" placeholder="请输入年份" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月份" prop="month">
              <el-input v-model="purifiedWaterDisinfectionForm.month" placeholder="请输入月份" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="purifiedWaterDisinfectionFormTitle === '新增纯化水系统消毒记录'
              || purifiedWaterDisinfectionFormTitle === '修改纯化水系统消毒记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="disinfectionDate"
              title="日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column field="situation" title="消毒情况">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="紫外消毒（运行）" />
                  <vxe-radio :label="2" content="臭氧消毒" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="duration"
              title="消毒时间"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="purifiedWaterDisinfectionFormTitle === '新增纯化水系统消毒记录'
                || purifiedWaterDisinfectionFormTitle === '修改纯化水系统消毒记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="purifiedWaterDisinfectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="purifiedWaterDisinfectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="年份">
        <el-input v-model="searchForm.month" placeholder="请输入年份" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="purifiedWaterDisinfectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="year" label="年份" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="purifiedWaterDisinfectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPurifiedWaterDisinfection,
  deletePurifiedWaterDisinfection,
  updatePurifiedWaterDisinfection,
  selectPurifiedWaterDisinfectionInfo,
  selectPurifiedWaterDisinfectionList
} from '@/api/device/purifiedWaterDisinfection'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      purifiedWaterDisinfectionDialogVisible: false,
      purifiedWaterDisinfectionFormTitle: '',
      purifiedWaterDisinfectionForm: {
        id: '',
        deviceId: '',
        deviceNo: '',
        deviceName: '',
        year: '',
        month: '',
        detailJson: ''
      },
      purifiedWaterDisinfectionFormRules: {
        month: [{ required: true, message: '月份不能为空', trigger: ['blur', 'change']}]
      },
      purifiedWaterDisinfectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        month: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectPurifiedWaterDisinfectionList(this.searchForm).then(res => {
      this.purifiedWaterDisinfectionPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    purifiedWaterDisinfectionDialogClose () {
      this.$refs.purifiedWaterDisinfectionFormRef.resetFields()
      this.detailList = []
    },
    purifiedWaterDisinfectionFormSubmit () {
      if (this.purifiedWaterDisinfectionFormTitle === '纯化水系统消毒记录详情') {
        this.purifiedWaterDisinfectionDialogVisible = false
        return
      }
      this.$refs.purifiedWaterDisinfectionFormRef.validate(async valid => {
        if (valid) {
          this.purifiedWaterDisinfectionForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.purifiedWaterDisinfectionFormTitle === '新增纯化水系统消毒记录') {
            this.purifiedWaterDisinfectionForm.id = ''
            await addPurifiedWaterDisinfection(this.purifiedWaterDisinfectionForm)
          } else if (this.purifiedWaterDisinfectionFormTitle === '修改纯化水系统消毒记录') {
            await updatePurifiedWaterDisinfection(this.purifiedWaterDisinfectionForm)
          }
          this.purifiedWaterDisinfectionPage = await selectPurifiedWaterDisinfectionList(this.searchForm)
          this.purifiedWaterDisinfectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.purifiedWaterDisinfectionFormTitle = '新增纯化水系统消毒记录'
      this.purifiedWaterDisinfectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePurifiedWaterDisinfection(row.id)
        if (this.purifiedWaterDisinfectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.purifiedWaterDisinfectionPage = await selectPurifiedWaterDisinfectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.purifiedWaterDisinfectionFormTitle = '修改纯化水系统消毒记录'
      this.purifiedWaterDisinfectionDialogVisible = true
      this.selectPurifiedWaterDisinfectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.purifiedWaterDisinfectionFormTitle = '纯化水系统消毒记录详情'
      this.purifiedWaterDisinfectionDialogVisible = true
      this.selectPurifiedWaterDisinfectionInfoById(row.id)
    },
    selectPurifiedWaterDisinfectionInfoById (id) {
      selectPurifiedWaterDisinfectionInfo(id).then(res => {
        this.purifiedWaterDisinfectionForm.id = res.id
        this.purifiedWaterDisinfectionForm.deviceId = res.deviceId
        this.purifiedWaterDisinfectionForm.deviceNo = res.deviceNo
        this.purifiedWaterDisinfectionForm.deviceName = res.deviceName
        this.purifiedWaterDisinfectionForm.year = res.year
        this.purifiedWaterDisinfectionForm.month = res.month
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPurifiedWaterDisinfectionList(this.searchForm).then(res => {
        this.purifiedWaterDisinfectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPurifiedWaterDisinfectionList(this.searchForm).then(res => {
        this.purifiedWaterDisinfectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPurifiedWaterDisinfectionList(this.searchForm).then(res => {
        this.purifiedWaterDisinfectionPage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.id === value)
        this.purifiedWaterDisinfectionForm.deviceNo = dev.deviceNo
        this.purifiedWaterDisinfectionForm.deviceName = dev.deviceName
      } else {
        this.purifiedWaterDisinfectionForm.deviceNo = ''
        this.purifiedWaterDisinfectionForm.deviceName = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
